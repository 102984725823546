import { createGtm } from "@gtm-support/vue-gtm";

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();

    if (config.public.gtmID) {
        nuxtApp.vueApp.use(
            createGtm({
                id: config.public.gtmID,
                defer: false,
                compatibility: false,
                enable: config.public.environment === "production",
                debug: config.public.environment !== "production",
                vueRouter: useRouter(),
                trackOnNextTick: false,
            })
        );
    }
});
