export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"We have answers to questions you haven't even thought of."}],"link":[],"style":[],"script":[],"noscript":[],"title":"Revagency"}

export const appRootTag = "body"

export const appRootId = "app"

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appLayoutTransition = {"name":"page","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appKeepalive = false