import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/Users/macgitlabrunner1/builds/EFFy8Dyq/5/web-applications/revagency-com/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/Users/macgitlabrunner1/builds/EFFy8Dyq/5/web-applications/revagency-com/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_plugins_router_mjs_Pg0DINazwm from "/Users/macgitlabrunner1/builds/EFFy8Dyq/5/web-applications/revagency-com/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import node_modules_nuxt_dist_pages_runtime_plugins_prefetch_client_mjs_3cwHvxIDEX from "/Users/macgitlabrunner1/builds/EFFy8Dyq/5/web-applications/revagency-com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import plugins_aos_client_js_Gr50AN4Ul1 from "/Users/macgitlabrunner1/builds/EFFy8Dyq/5/web-applications/revagency-com/plugins/aos.client.js";
import plugins_fontawesome_js_klhsrycjcK from "/Users/macgitlabrunner1/builds/EFFy8Dyq/5/web-applications/revagency-com/plugins/fontawesome.js";
import plugins_rcl_js_KlK1RUZqM6 from "/Users/macgitlabrunner1/builds/EFFy8Dyq/5/web-applications/revagency-com/plugins/rcl.js";
import plugins_sentry_client_js_GoGQuZo4Et from "/Users/macgitlabrunner1/builds/EFFy8Dyq/5/web-applications/revagency-com/plugins/sentry.client.js";
import plugins_vue_gtm_client_js_Cm24Dsc5BN from "/Users/macgitlabrunner1/builds/EFFy8Dyq/5/web-applications/revagency-com/plugins/vue-gtm.client.js";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_plugins_router_mjs_Pg0DINazwm,
  node_modules_nuxt_dist_pages_runtime_plugins_prefetch_client_mjs_3cwHvxIDEX,
  plugins_aos_client_js_Gr50AN4Ul1,
  plugins_fontawesome_js_klhsrycjcK,
  plugins_rcl_js_KlK1RUZqM6,
  plugins_sentry_client_js_GoGQuZo4Et,
  plugins_vue_gtm_client_js_Cm24Dsc5BN
]