import { default as pages_47index_46vueMeta } from "/Users/macgitlabrunner1/builds/EFFy8Dyq/5/web-applications/revagency-com/pages/index.vue?macro=true";
import { default as pages_47privacy_45policy_46vueMeta } from "/Users/macgitlabrunner1/builds/EFFy8Dyq/5/web-applications/revagency-com/pages/privacy-policy.vue?macro=true";
export default [
  {
    name: pages_47index_46vueMeta?.name ?? "index",
    path: pages_47index_46vueMeta?.path ?? "/",
    children: [],
    meta: pages_47index_46vueMeta,
    alias: pages_47index_46vueMeta?.alias || [],
    redirect: pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/Users/macgitlabrunner1/builds/EFFy8Dyq/5/web-applications/revagency-com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47privacy_45policy_46vueMeta?.name ?? "privacy-policy",
    path: pages_47privacy_45policy_46vueMeta?.path ?? "/privacy-policy",
    children: [],
    meta: pages_47privacy_45policy_46vueMeta,
    alias: pages_47privacy_45policy_46vueMeta?.alias || [],
    redirect: pages_47privacy_45policy_46vueMeta?.redirect || undefined,
    component: () => import("/Users/macgitlabrunner1/builds/EFFy8Dyq/5/web-applications/revagency-com/pages/privacy-policy.vue").then(m => m.default || m)
  }
]