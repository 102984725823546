<template>
    <Html lang="en" class="h-full bg-white">
        <Body class="h-full">
            <NuxtLayout :name="layout">
                <NuxtPage />
            </NuxtLayout>
        </Body>
    </Html>
</template>

<script setup>
const layout = ref("default");

useHead({
    link: [
        {
            rel: "preload",
            href: "/fonts/titillium-web-v15-latin-600.woff2",
            as: "font",
            crossorigin: "",
            type: "font/woff2",
        },
        {
            rel: "preload",
            href: "/fonts/titillium-web-v15-latin-regular.woff2",
            as: "font",
            crossorigin: "",
            type: "font/woff2",
        },
        {
            rel: "preload",
            href: "/fonts/titillium-web-v15-latin-300.woff2",
            as: "font",
            crossorigin: "",
            type: "font/woff2",
        },
        {
            rel: "stylesheet",
            href: "/fonts/titillium-font.css",
        },
    ],
});
</script>

<style>
.page-enter-active,
.page-leave-active {
    transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(1rem);
}
</style>
