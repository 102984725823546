import { library, config } from "@fortawesome/fontawesome-svg-core";
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";
import {
    faChartMixed,
    faLaptopCode,
    faClipboardList,
} from "@fortawesome/pro-light-svg-icons";

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false;

library.add(faChartMixed, faLaptopCode, faClipboardList);

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component("FontAwesomeIcon", FontAwesomeIcon, {});
    nuxtApp.vueApp.component("FontAwesomeLayers", FontAwesomeLayers, {});
});
